import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(area_id) {
		const url = API_ROUTES.subarea.get
			.replace(':area_id', area_id)

		const { data } = await useJwt.get(url)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.subarea.get}/${id}`)

		return data
	},
	save(area_id, id, data) {
		const url = API_ROUTES.subarea.save
			.replace(':area_id', area_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	delete(area_id, id) {
		const url = API_ROUTES.subarea.update
			.replace(':area_id', area_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	update(area_id, id, data) {
		const url = API_ROUTES.subarea.update
			.replace(':area_id', area_id)
			.replace(':id', id)
		return useJwt.patch(url, data)
	},
}
